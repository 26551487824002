import { useState } from 'react'
import { ViewHeader } from '../../components/ViewHeader'
import { PlayerRow } from './player'
import { Table } from '../../components/Table'
import { TableData } from '../../types/table'
import { Player } from '../../types/player'

export function Players() {
  const [loading, setLoading] = useState(false)

  const filter = (players: TableData<Player>, searchingBy: string) => {
    return players.all.filter(
      (p) =>
        p.username.includes(searchingBy) ||
        p.first_name?.includes(searchingBy) ||
        p.last_name?.includes(searchingBy) ||
        p.email?.includes(searchingBy) ||
        p.movile_number?.includes(searchingBy)
    )
  }

  return (
    <>
      <ViewHeader loading={loading} heading={'Jugadores'} />
      <Table<Player>
        url="/players"
        searchPlaceholder="Buscar por usuario, nombre, email o telefono"
        entityNamePlural="jugadores"
        RowElement={PlayerRow}
        loading={loading}
        setLoading={setLoading}
        columns={[
          { selector: 'username', type: 'string', name: 'Usuario' },
          { selector: 'email', type: 'string', name: 'Email' },
          { selector: 'first_name', type: 'string', name: 'Nombre' },
          { selector: 'movile_number', type: 'number', name: 'Teléfono' },
          { selector: 'created_at', type: 'datetime', name: 'Fecha' },
          { name: '' }
        ]}
        filter={filter}
      />
    </>
  )
}
