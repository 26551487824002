import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { PencilIcon } from '../../../components/icons/pencil'
import { AuthContext } from '../../../components/authProvider'
import { toast } from 'react-toastify'
import { ViewHeader } from '../../../components/ViewHeader'
import { Link } from 'react-router-dom'
import { IAuthContext } from '../../../types/auth'
import { RootBankAccount } from '../../../types/bankAccount'
import { Label } from './styled'
import { FormFooter } from '../../../components/FormFooter'
import { BankSelect } from './BankSelect'

const defaultBankAccount: RootBankAccount = {
  name: '',
  dni: '',
  // bankName: '',
  bankId: '',
  accountNumber: '',
  clabe: '',
  alias: ''
}

export function BankAccountView() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [editable, setEditable] = useState(false)
  const [bankAccount, setBankAccount] =
    useState<RootBankAccount>(defaultBankAccount)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const nameRef = useRef<HTMLInputElement>(null)

  const toggleEditable: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEditable(e.target.checked)
    nameRef.current?.focus()
  }

  useEffect(() => {
    apiInit()
      .then((api) => api.get('/agent/bank-account'))
      .then((res) => {
        if (res.status === 200) return res.data.data
        throw res
      })
      .then(setBankAccount)
      .catch(() => {
        toast('No pudimos cargar los datos', { type: 'error' })
      })
      .finally(() => setLoading(false))
  }, [])

  const save = (e: React.FormEvent<HTMLFormElement>) => {
    setSaving(true)
    e.preventDefault()
    const data = Object.fromEntries(new FormData(e.target as HTMLFormElement))
    apiInit()
      .then((api) => api.post('/agent/bank-account', data))
      .then((res) => {
        if (res.status === 200) return res.data.data
        throw res
      })
      .then((result) => {
        toast('Cambios guardados.', { type: 'success' })
        setBankAccount(result)
      })
      .catch(() => {
        toast('Algo salió mal, por favor volvé a intentar.', { type: 'error' })
      })
      .finally(() => setSaving(false))
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setBankAccount({
      ...bankAccount,
      [name]: value
    })
  }

  return (
    <>
      <ViewHeader
        loading={loading}
        heading="Cuenta Bancaria"
        subheading="Acá podés ver y editar los datos de tu cuenta bancaria"
      />

      <Label>
        <PencilIcon height={20} width={20} />
        &nbsp; Editar &nbsp;
        <input
          type="checkbox"
          name="switch"
          id="switch"
          role="switch"
          onChange={toggleEditable}
          value={0}
        />
      </Label>
      <form onSubmit={save}>
        <div className="grid">
          <input
            onChange={handleChange}
            type="text"
            placeholder="Nombre del beneficiario"
            name="name"
            readOnly={!editable}
            value={bankAccount?.name}
            ref={nameRef}
          />
          <input
            onChange={handleChange}
            type="text"
            placeholder="DNI"
            name="dni"
            inputMode="numeric"
            readOnly={!editable}
            value={bankAccount?.dni}
          />
        </div>
        <BankSelect
          name="bankId"
          value={bankAccount?.bankId}
          onChange={handleChange}
          readOnly={!editable}
        />
        <input
          onChange={handleChange}
          type="text"
          placeholder="Número de cuenta"
          name="accountNumber"
          readOnly={!editable}
          value={bankAccount?.accountNumber}
        />
        <div className="grid">
          <input
            onChange={handleChange}
            type="text"
            placeholder="CLABE"
            name="clabe"
            readOnly={!editable}
            value={bankAccount?.clabe}
          />
          <input
            onChange={handleChange}
            type="text"
            placeholder="Alias"
            name="alias"
            readOnly={!editable}
            value={bankAccount?.alias}
          />
        </div>
        <FormFooter>
          <Link to=".." style={{ justifySelf: 'flex-start' }}>
            <button className="secondary">Volver</button>
          </Link>
          <button
            type="submit"
            disabled={!editable}
            style={{ transitionProperty: 'opacity' }}
            aria-busy={saving}
          >
            Guardar
          </button>
        </FormFooter>
      </form>
    </>
  )
}
