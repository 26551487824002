import { ConfigItem } from '../../../components/ConfigItem'

export function BankAccountConfig() {
  return (
    <ConfigItem
      title={'Configurar datos bancarios'}
      subtitle={''}
      redirect="cuenta-bancaria"
    />
  )
}
