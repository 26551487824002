import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../components/authProvider'
import { ConfigItem } from '../../components/ConfigItem'
import { IAuthContext } from '../../types/auth'

export function Notifications() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(false)
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
    async function findSubscription() {
      const registration = await navigator.serviceWorker.getRegistration()

      if (!registration) return

      const subscription = await registration.pushManager.getSubscription()

      if (subscription) {
        setSubscribed(true)
      }

      setLoading(false)
    }
    findSubscription()
  })

  const unsubscribe = async () => {
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.getSubscription()
    if (subscription) subscription.unsubscribe()

    return { subscription, registration }
  }

  const subscribe = async (applicationServerKey: string) => {
    const url = '/web-push/subscription'
    const api = await apiInit()
    return unsubscribe()
      .then(({ registration }) =>
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey
        })
      )
      .then((subscription) => api.post(url, subscription))
      .then((res) => {
        if (res.status === 201) return res
        else throw res
      })
  }

  const getPublicKey = async (): Promise<string> => {
    const url = '/web-push/pubkey'
    return apiInit()
      .then((api) => api.get(url))
      .then((res) => {
        if (res.status === 200) return res.data.data
        else throw res
      })
  }

  const removeSubscription = async () => {
    const url = '/web-push/delete'
    const api = await apiInit()
    return unsubscribe()
      .then(({ subscription }) =>
        api.post(url, { data: { endpoint: subscription?.endpoint } })
      )
      .catch(() => toast('Algo salió mal, por favor volvé a intentar.'))
  }

  const hadleSubscription: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    if (e.target.checked) {
      if (!('serviceWorker' in navigator)) {
        toast(
          'No podemos mostrar notificaciones en este dispositivo. Intentá desde otro dispositivo o actualizando tu navegador.'
        )
      }
      getPublicKey()
        .then(subscribe)
        .then(() => setSubscribed(true))
        .catch(() =>
          toast(
            'No pudimos subscribirte a las notificaciones. Por favor intentá de nuevo.',
            { type: 'error' }
          )
        )
    } else {
      removeSubscription().then(() => setSubscribed(false))
    }
  }

  return (
    <ConfigItem
      title="Fichas insuficientes"
      subtitle={
        'Recibir notificaciones cuando el balance no sea suficiente para transferir fichas a los jugadores.'
      }
      toggleHandler={hadleSubscription}
      loading={loading}
      checked={subscribed}
    />
  )
}
