import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../../components/authProvider'
import { LoginForm } from './form'

export function Login() {
  const { isAuthed } = useContext(AuthContext)

  return isAuthed ? <Navigate to="/pagos" /> : <LoginForm />
}
