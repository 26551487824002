import { Outlet, useNavigation } from 'react-router-dom'
import Header from './components/Header'
import styled from 'styled-components'
import { Footer } from './components/Footer'

const Main = styled.main`
  --main-inline-padding: 0.5rem;
  --main-inline-margin: 1rem;
  padding-inline: var(--main-inline-padding);
  position: relative;
  width: min(1200px, 100% - calc(2 * var(--main-inline-margin)));
  margin-inline: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Spinner = styled.p`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`

export default function Layout() {
  const navigation = useNavigation()
  return (
    <>
      <Header />

      <Main>
        <Spinner aria-busy={navigation.state === 'loading'}></Spinner>
        <Outlet />
      </Main>

      <Footer />
    </>
  )
}
