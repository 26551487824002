import { BankSelectProps } from '../../../types/bankSelect'

export function BankSelect({
  name,
  value,
  onChange,
  readOnly
}: BankSelectProps) {
  return (
    <select name={name} value={value} onChange={onChange} disabled={readOnly}>
      <option disabled selected value="">
        Banco
      </option>
      <option value="40138">ABC CAPITAL</option>
      <option value="40133">ACTINVER</option>
      <option value="40062">AFIRME</option>
      <option value="90661">ALTERNATIVOS</option>
      <option value="90706">ARCUS</option>
      <option value="90659">ASP INTEGRA OPC</option>
      <option value="40128">AUTOFIN</option>
      <option value="40127">AZTECA</option>
      <option value="37166">BaBien</option>
      <option value="40030">BAJIO</option>
      <option value="40002">BANAMEX</option>
      <option value="40154">BANCO COVALTO</option>
      <option value="37006">BANCOMEXT</option>
      <option value="40137">BANCOPPEL</option>
      <option value="40160">BANCO S3</option>
      <option value="40152">BANCREA</option>
      <option value="37019">BANJERCITO</option>
      <option value="40147">BANKAOOL</option>
      <option value="40106">BANK OF AMERICA</option>
      <option value="40159">BANK OF CHINA</option>
      <option value="37009">BANOBRAS</option>
      <option value="40072">BANORTE</option>
      <option value="40058">BANREGIO</option>
      <option value="40060">BANSI</option>
      <option value="2001">BANXICO</option>
      <option value="40129">BARCLAYS</option>
      <option value="40145">BBASE</option>
      <option value="40012">BBVA MEXICO</option>
      <option value="40112">BMONEX</option>
      <option value="90677">CAJA POP MEXICA</option>
      <option value="90683">CAJA TELEFONIST</option>
      <option value="90630">CB INTERCAM</option>
      <option value="40143">CIBANCO</option>
      <option value="90631">CI BOLSA</option>
      <option value="90901">CLS</option>
      <option value="90903">CoDi Valida</option>
      <option value="40130">COMPARTAMOS</option>
      <option value="40140">CONSUBANCO</option>
      <option value="90652">CREDICAPITAL</option>
      <option value="90688">CREDICLUB</option>
      <option value="90680">CRISTOBAL COLON</option>
      <option value="90723">Cuenca</option>
      <option value="40151">DONDE</option>
      <option value="90616">FINAMEX</option>
      <option value="90634">FINCOMUN</option>
      <option value="90689">FOMPED</option>
      <option value="90685">FONDO (FIRA)</option>
      <option value="90601">GBM</option>
      <option value="37168">HIPOTECARIA FED</option>
      <option value="40021">HSBC</option>
      <option value="40155">ICBC</option>
      <option value="40036">INBURSA</option>
      <option value="90902">INDEVAL</option>
      <option value="40150">INMOBILIARIO</option>
      <option value="40136">INTERCAM BANCO</option>
      <option value="90686">INVERCAP</option>
      <option value="40059">INVEX</option>
      <option value="40110">JP MORGAN</option>
      <option value="90653">KUSPIT</option>
      <option value="90670">LIBERTAD</option>
      <option value="90602">MASARI</option>
      <option value="90722">Mercado Pago W</option>
      <option value="40042">MIFEL</option>
      <option value="40158">MIZUHO BANK</option>
      <option value="90600">MONEXCB</option>
      <option value="40108">MUFG</option>
      <option value="40132">MULTIVA BANCO</option>
      <option value="37135">NAFIN</option>
      <option value="90638">NU MEXICO</option>
      <option value="90710">NVIO</option>
      <option value="40148">PAGATODO</option>
      <option value="90620">PROFUTURO</option>
      <option value="40156">SABADELL</option>
      <option value="40014">SANTANDER</option>
      <option value="40044">SCOTIABANK</option>
      <option value="40157">SHINHAN</option>
      <option value="90646">STP</option>
      <option value="90703">TESORED</option>
      <option value="90684">TRANSFER</option>
      <option value="90656">UNAGRA</option>
      <option value="90617">VALMEX</option>
      <option value="90605">VALUE</option>
      <option value="90608">VECTOR</option>
      <option value="40113">VE POR MAS</option>
      <option value="40141">VOLKSWAGEN</option>
    </select>
  )
}
