import { useRouteError } from "react-router-dom"

export default function Error() {
    const error = useRouteError();
    if (window.location.href.includes('localhost')) console.error(error);
    return (
        <div className="grid" style={{height: "100svh", placeItems: "center"}}>
            <article style={{ textAlign: "center" }}>
                {/* @ts-ignore */}
                <h1>{error.status}</h1>
                {/* @ts-ignore */}
                <h2>{error.statusText}</h2>
            </article>
        </div>
    )
}