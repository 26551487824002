import { SortingBy } from "../../types/table";

/**
 * Accede una propiedad de un objeto a traves de dot.notation
 * @param obj El objeto
 * @param key La propiedad a acceder
 */
function getDescendant(obj: any, key: string): string|number {
    const arr = key.split(".");
    // @ts-ignore
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
}

/** Ordenar por columna */
export  function sortColumn(a: Record<string, any>, b: Record<string, any>, sortingBy: SortingBy) {
    const first = getDescendant(sortingBy.ascending ? a : b, sortingBy.selector);
    const second = getDescendant(sortingBy.ascending ? b : a, sortingBy.selector);
    let result;
    switch (sortingBy.type) {
        case 'datetime':
            result = +new Date(first) - +new Date(second);
            break;
        case 'string':
            //@ts-ignore
            result = (first ?? "").localeCompare(second ?? "");
            break;
        default: 
            result = Number(first) - Number(second);

    }
    return result;
}