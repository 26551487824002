import { useContext, useEffect, useState } from 'react'
import { PAYMENT_STATUS } from '../../config'
import { PaymentCTAProps } from '../../types/payment'
import { FinanceContext } from '../../components/financeProvider'

export function PaymentCTA({ data, cb, busy }: PaymentCTAProps) {
  const { autoPayments } = useContext(FinanceContext)
  const [btnText, setBtnText] = useState('')
  useEffect(() => {
    if (autoPayments)
      switch (data.status) {
        case PAYMENT_STATUS.REQUESTED:
          setBtnText('Pagar')
          break
        case PAYMENT_STATUS.PENDING:
          setBtnText('Autorizar')
          break
        case PAYMENT_STATUS.COMPLETED:
          setBtnText('-')
          break
        default:
          setBtnText('Refrescar')
      }
    else
      setBtnText(data.status === PAYMENT_STATUS.REQUESTED ? 'Confirmar' : '-')
  }, [data])
  return (
    <>
      <button
        className="sm"
        onClick={cb}
        disabled={busy || btnText === '-'}
        style={{ width: '100%' }}
      >
        <span aria-busy={busy}>{btnText}</span>
      </button>
    </>
  )
}
