import { ViewHeaderProps } from '../../types/viewHeader'

export function ViewHeader(props: ViewHeaderProps) {
  const { loading, heading, subheading, children } = props
  return (
    <>
      <hgroup>
        <h1 style={{ marginTop: '1.125em' }}>
          {heading} <span aria-busy={loading}></span>
        </h1>
        <p>{subheading}</p>
      </hgroup>
      {children}
    </>
  )
}
