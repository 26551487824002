import { ViewHeader } from '../../components/ViewHeader'
import { OnCall } from './bot/onCall'
import { Qr } from './bot/QR/QrConfig'
import { BankAccountConfig } from './BankAccount/BankAccountConfig'
import { Notifications } from './notifications'
import { ContactNumbersConfig } from './bot/ContactNumbers/ContactNumbersConfig'
import { PaymentsConfig } from './Payments'

export function Config() {
  return (
    <>
      <ViewHeader heading={'Configuración'} loading={false} />
      <h3>Bot</h3>
      <hr />
      <Qr />
      <hr />
      <OnCall />
      <hr />
      <ContactNumbersConfig />
      <hr />
      <h3>Cuenta Bancaria</h3>
      <hr />
      <BankAccountConfig />
      <hr />
      <h3>Notificaciones</h3>
      <hr />
      <Notifications />
      <hr />
      <h3>Pagos</h3>
      <hr />
      <PaymentsConfig />
      <hr />
    </>
  )
}
