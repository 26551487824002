import { useContext, useEffect, useState } from 'react'
import { ViewHeader } from '../../../../components/ViewHeader'
import { AuthContext } from '../../../../components/authProvider'
import { APIResponse } from '../../../../types/api'
import { SupportResponse } from '../../../../types/config'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { IAuthContext } from '../../../../types/auth'
import { FormFooter } from '../../../../components/FormFooter'

export function ContactNumbersView() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [loading, setLoading] = useState(true)
  const [numbers, setNumbers] = useState<SupportResponse>({})

  useEffect(() => {
    async function fetchData() {
      const api = await apiInit()
      const numbers = await api.get<APIResponse<SupportResponse>>(
        '/agent/support'
      )
      setNumbers(numbers.data.data)
    }

    fetchData().finally(() => setLoading(false))
  }, [])

  function handleInput(e: React.ChangeEvent<HTMLFieldSetElement>) {
    // @ts-ignore
    const { name, value } = e.target
    setNumbers({ ...numbers, [name]: value })
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true)
      document.querySelectorAll('small').forEach((el) => (el.innerText = ''))
      e.preventDefault()
      const api = await apiInit()
      const response = await api.post<APIResponse<SupportResponse>>(
        '/agent/support',
        numbers
      )
      if (response.status !== 200) throw response
      setNumbers(response.data.data)
      toast.success('Cambios guardados.')
    } catch (e: any) {
      if (e.status >= 400 && e.status < 500)
        toast.error('Algo salió mal, por favor volvé a intentar')
      else if (e.status > 500)
        toast.error(
          'Error en el servidor. Por favor volvé a intentar en unos minutos.'
        )
    } finally {
      setLoading(false)
    }
  }

  const handleInvalid: React.FormEventHandler<HTMLFormElement> = (e) => {
    //@ts-ignore
    e.target.nextElementSibling.innerText =
      'El teléfeno debe contener solo números y tener entre 10 y 20 caracteres.'
  }

  return (
    <>
      <ViewHeader loading={loading} heading={'Teléfonos de Soporte'} />
      <form onSubmit={handleSubmit} onInvalid={handleInvalid}>
        <fieldset onInput={handleInput}>
          <label htmlFor="bot">
            Bot
            <input
              defaultValue={numbers.bot_phone ?? ''}
              id="bot"
              inputMode="numeric"
              maxLength={20}
              minLength={10}
              name="bot_phone"
              pattern="[0-9]{10,20}"
              placeholder="Solo numeros, sin + y sin espacios."
              type="text"
            />
            <small style={{ color: 'var(--pico-del-color' }}></small>
          </label>
          <label>
            Soporte humano
            <input
              defaultValue={numbers.human_phone ?? ''}
              id="human"
              inputMode="numeric"
              maxLength={20}
              minLength={10}
              name="human_phone"
              pattern="[0-9]{10,20}"
              placeholder="Solo numeros, sin + y sin espacios."
              type="text"
            />
            <small style={{ color: 'var(--pico-del-color' }}></small>
          </label>
        </fieldset>

        <FormFooter>
          <Link to=".." style={{ justifySelf: 'flex-start' }}>
            <button className="secondary">Volver</button>
          </Link>
          <button type="submit" aria-busy={loading} disabled={loading}>
            Guardar
          </button>
        </FormFooter>
      </form>
    </>
  )
}
