import { FormEventHandler, useEffect, useState } from "react";
import { TableNavigationProps } from "../../../types/table";
import { Wrapper, Div, Input } from "./styled";

export function TableNavigation({
  page,
  setPage,
  total,
  itemsPerPage,
  setItemsPerPage,
}: TableNavigationProps) {
  const handlePageInput: FormEventHandler<HTMLInputElement> = (e) => {
    const value = Number((e.target as HTMLInputElement).value);
    if (value < 0) return;
    setPage(value);
  };
  const [totalPages, setTotalPages] = useState(1);

  const handleItemsPerPageInput: FormEventHandler<HTMLInputElement> = (e) => {
    const value = Number((e.target as HTMLInputElement).value);
    if (value <= 0) return;
    setItemsPerPage(value);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  return (
    <Wrapper>
      <Div>
        <small>Página</small>&nbsp;
        <Input
          onInput={handlePageInput}
          type="number"
          min={1}
          max={totalPages}
          value={page}
        />
        &nbsp;
        <span>
          <small>de {Math.ceil(total / itemsPerPage)}</small>
        </span>
      </Div>
      <Div>
        <small>Elementos por página</small>&nbsp;
        <Input
          onInput={handleItemsPerPageInput}
          min={10}
          max={200}
          step={10}
          type="number"
          value={itemsPerPage}
        />
      </Div>
      <span>
        <small>Total: {total}</small>
      </span>
    </Wrapper>
  );
}