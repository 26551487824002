import { useEffect, useRef, useState } from 'react'
import { Controls, Input, Label, Segment, Wrapper } from './styled'
import { SegmentSelectProps } from '../../types/segmentSelect'

export function SegmentSelect(props: SegmentSelectProps) {
  const { name, segments, callback, defaultIndex, controlRef } = props
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const componentReady = useRef(false)

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true
  }, [])

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref
    const { offsetWidth, offsetLeft } = activeSegmentRef!.current
    const { style } = controlRef.current

    style.setProperty('--highlight-width', `${offsetWidth}px`)
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`)
  }, [activeIndex, callback, controlRef, segments])

  const onInputChange = (value: string, index: number) => {
    setActiveIndex(index)
    callback(value, index)
  }

  return (
    <Wrapper ref={controlRef}>
      <Controls className={`${componentReady.current ? 'ready' : 'idle'}`}>
        {segments?.map((item, i) => (
          <Segment
            key={item.value}
            className={`segment ${i === activeIndex ? 'active' : 'inactive'}`}
            ref={item.ref}
          >
            <Input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <Label htmlFor={item.label}>{item.label}</Label>
          </Segment>
        ))}
      </Controls>
    </Wrapper>
  )
}
