import { ChevronIcon } from '../icons/chevron'
import { Link } from 'react-router-dom'
import { Action, Description, Main } from './styled'
import { ConfigItemProps } from '../../types/config'

export function ConfigItem(props: ConfigItemProps) {
  const { title, subtitle, loading, toggleHandler, checked, redirect } = props
  return (
    <>
      <Main>
        <Description>
          {title} <br />
          <small>{subtitle}</small>
        </Description>
        <Action>
          {toggleHandler && (
            <input
              type="checkbox"
              role="switch"
              onChange={toggleHandler}
              value={1}
              checked={checked}
              disabled={loading}
            />
          )}
          {redirect && (
            <Link to={redirect} style={{ paddingInline: '.5em' }}>
              <ChevronIcon width={20} height={20} />
            </Link>
          )}
        </Action>
      </Main>
    </>
  )
}
