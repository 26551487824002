export function parseDepositStatus(status: string) {
  let translatedStatus = ''
  let description = ''
  switch (status) {
    case 'pending':
      translatedStatus = 'Pendiente'
      description = 'Esperando confirmacion por Alquimia'
      break
    case 'verified':
      translatedStatus = 'Verificado'
      description = 'Verificado en Alquimia. Fichas no enviadas.'
      break
    case 'confirmed':
      translatedStatus = 'Confirmado'
      description =
        'Pago verificado y fichas enviadas al jugador. Error al registrar movimiento en la base de datos.'
      break
    case 'completed':
      translatedStatus = 'Completado'
      description = 'No hay acciones pendientes'
      break
    case 'deleted':
      translatedStatus = 'Eliminado'
      description = 'Eliminado por el agente'
      break
  }
  return { translatedStatus, description }
}
