import { CopyableText } from '../../components/CopyableText'
import { parseDate } from '../../components/table-utilities/parse-date'
import { Player } from '../../types/player'
import { PlayerActions } from './playerActions'

export function PlayerRow({ data }: { data: Player }) {
  const fullName =
    data.first_name || data.last_name
      ? data.first_name + ' ' + data.last_name
      : '-'
  return (
    <tr>
      <td>{data.username}</td>
      <td>
        <CopyableText content={data.email} />
      </td>
      <td>{fullName}</td>
      <td>{data.movile_number}</td>
      <td>{parseDate(data.created_at)}</td>
      <td>
        <PlayerActions player={data} />
      </td>
    </tr>
  )
}
