import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  //margin-bottom: var(--pico-typography-spacing-vertical);
`;
export const Description = styled.p`
  margin: 0;
  padding: 0;
`;
export const Action = styled.div`
  display: grid;
  place-content: center;
  // cursor: pointer;
`;
