import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../config'
import { AuthContext } from '../../components/authProvider'
import { toast } from 'react-toastify'

export function LoginForm() {
  const { login } = useContext(AuthContext)
  const navigate = useNavigate()

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const credentials = {
      username: form.username.value,
      password: form.password.value
    }
    const loginResult = await axios.post(
      config.BASE_URL + '/agent/login',
      credentials,
      {
        validateStatus: () => true,
        withCredentials: false
      }
    )
    if (loginResult.status === 200) {
      // Store token
      login(loginResult.data.data.access)
      // Redirect to dashboard
      navigate('/pagos')
    } else if (
      loginResult.status === 404 &&
      loginResult.data.code === 'credenciales_invalidas'
    ) {
      toast('Credenciales invalidas', { type: 'error' })
    } else {
      toast('Error en la conexión', { type: 'error' })
    }
  }

  return (
    <section
      className="container"
      style={{ marginTop: 'var(--pico-block-spacing-vertical' }}
    >
      <article>
        <h1>Ingresar al panel</h1>

        <form method="POST" onSubmit={(e) => submit(e)}>
          <input type="text" name="username" placeholder="Usuario" />
          <input
            type="password"
            autoComplete="current-password"
            name="password"
            placeholder="Contraseña"
          />
          <button type="submit">Ingresar</button>
        </form>
      </article>
    </section>
  )
}
