import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from './authProvider'
import { ProtectedProps } from '../types/protected'

/**
 * Wrapper element that redirects to /login if user is not authenticated
 */
export default function Protected({ target }: ProtectedProps) {
  const { isAuthed } = useContext(AuthContext)

  return isAuthed ? target : <Navigate to="/login" />
}
