import { parseDepositStatus } from './utils'

export function TableDepositStatus({ status }: { status: string }) {
  const { translatedStatus, description } = parseDepositStatus(status)

  return (
    <span data-tooltip={description} data-placement="left">
      {translatedStatus}
    </span>
  )
}
