import { ConfigItem } from '../../../../components/ConfigItem'

export function ContactNumbersConfig() {
  return (
    <ConfigItem
      title={'Teléfonos de soporte'}
      subtitle={''}
      redirect="bot/support"
    />
  )
}
