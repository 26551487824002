import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../../components/authProvider'
import { ConfigItem } from '../../../components/ConfigItem'
import { IAuthContext } from '../../../types/auth'

export function OnCall() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [_loading, setLoading] = useState(false)
  const [onCall, setOnCall] = useState(false)

  useEffect(() => {
    async function fetchOnCallStatus() {
      const api = await apiInit()
      const result = await api.get('/agent/on-call')

      if (result.status === 200) {
        const active: boolean = result.data.data
        setOnCall(active)
      }

      setLoading(false)
    }
    fetchOnCallStatus()
  }, [])

  const submitOnCallStatus = async (active: boolean) => {
    try {
      const api = await apiInit()
      const result = await api.post('/agent/on-call', { active })
      if (result.status !== 200) throw result
      setOnCall(active)
    } catch (e) {
      toast('Algo salió mal, por favor volvé a intentar', { type: 'error' })
    }
  }

  const handleToggle: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    setLoading(true)
    await submitOnCallStatus(e.target.checked)
    setTimeout(() => setLoading(false), 200)
  }

  return (
    <ConfigItem
      title="Guardia"
      subtitle={
        'Modifica el flujo del bot para que muestre el submenú "contactanos".'
      }
      loading={false}
      toggleHandler={handleToggle}
      checked={onCall}
    />
  )
}
