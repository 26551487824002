import { config } from "../../config";
import { StyledFooter } from "./styled";

export function Footer() {
    return <>
        <StyledFooter>
            <small>Ambiente: { config.ENV }</small> <br/>
            <small>Monitor de estado: <a href={`${config.UPTIME_MONITOR_URL}`} target="_blank">{ config.UPTIME_MONITOR_URL }</a></small>
        </StyledFooter>
    </>
}