import styled from "styled-components"

export const BalanceWrapper = styled.div`
right: var(--main-inline-padding, 0);
background: #111;
padding: .375rem;
border-radius: var(--border-radius);
cursor: pointer !important;
display: inline-flex;
flex-direction: column;
border-bottom: none !important;

@media (prefers-color-scheme: light) {
    background: #eee;
}
`;
