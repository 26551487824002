import { useState, useRef, useContext, useCallback, useEffect } from 'react'
import { AuthContext } from '../authProvider'
import { sortColumn } from '../table-utilities/sort'
import { toast } from 'react-toastify'
import { SegmentSelect } from '../SegmentSelect'
import { TableNavigation } from '../table-utilities/TableNavigation'
import { ApiResponse, SortingBy, TableProps } from '../../types/table'
import { SortableColumn } from '../table-utilities/SortableColumn'
import { IAuthContext } from '../../types/auth'

export function Table<T extends { id: string }>({
  url,
  entityNamePlural,
  defaultFilter,
  quickFilters,
  RowElement,
  loading,
  setLoading,
  ExtraTableHeadRow,
  columns,
  filter,
  searchPlaceholder
}: TableProps) {
  const [data, setData] = useState<{
    all: T[]
    display: T[]
  }>({ all: [], display: [] })
  const [totalData, setTotalData] = useState(0)
  const [sortingBy, setSortingBy] = useState<SortingBy>({
    selector: 'created_at',
    type: 'datetime',
    ascending: false
  })
  const [searchingBy, setSearchingBy] = useState<string>('')
  const [filteringBy, setFitleringBy] = useState<string>(defaultFilter ?? '')
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const searchInput = useRef<HTMLInputElement>(null)
  const { apiInit } = useContext(AuthContext) as IAuthContext

  const fetchData = useCallback(async () => {
    const fullUrl =
      url +
      '?page=' +
      page +
      '&items_per_page=' +
      itemsPerPage +
      '&search=' +
      searchingBy +
      '&sort_column=' +
      sortingBy.selector +
      '&sort_direction=' +
      (sortingBy.ascending ? 'asc' : 'desc')

    try {
      const api = await apiInit()
      const res = await api.get<ApiResponse<T>>(fullUrl)
      if (res.status === 200) {
        setData({ all: res.data.data.result, display: [] })
        setTotalData(res.data.data.total)
      } else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error(`No pudimos descargar los ${entityNamePlural}`)
    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false)
    }
  }, [page, itemsPerPage, searchingBy, sortingBy])

  useEffect(() => {
    setLoading(true)
    const timeoutId = setTimeout(fetchData, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [fetchData])

  const handleSearchInput = () => {
    const q = (searchInput.current as HTMLInputElement).value.toLowerCase()
    setSearchingBy(q)
  }

  const sortHandler = (sortingBy: SortingBy) => {
    setSortingBy(sortingBy)
    setData((prev) => ({
      all: prev.all,
      display: prev.display.sort((a, b) =>
        sortColumn(
          a as Record<string, any>,
          b as Record<string, any>,
          sortingBy
        )
      )
    }))
  }

  const handleSegmentSelect = (status: string) => {
    setFitleringBy(status)
  }

  useEffect(() => {
    if (!data.all) return
    const filtered = filter(data, searchingBy, filteringBy).sort(
      (a, b) => sortColumn(a, b, sortingBy) as number
    )
    setData((prev) => ({ all: prev.all, display: filtered }))
  }, [filteringBy, sortingBy, searchingBy, data.all])

  return (
    <>
      <input
        type="search"
        id="search"
        name="search"
        placeholder={searchPlaceholder}
        onKeyUp={handleSearchInput}
        ref={searchInput}
      />
      {quickFilters && (
        <SegmentSelect
          name={'data-filter'}
          segments={quickFilters}
          callback={handleSegmentSelect}
          defaultIndex={0}
          controlRef={useRef()}
        />
      )}
      <TableNavigation
        total={totalData}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
      />
      <figure className="overflow-auto">
        <table>
          <caption></caption>
          <thead>
            {ExtraTableHeadRow}
            <tr>
              {columns.map((c) =>
                c.selector && c.type ? (
                  <SortableColumn
                    selector={c.selector}
                    type={c.type}
                    sortingBy={sortingBy}
                    name={c.name}
                    handleSort={sortHandler}
                    key={c.selector}
                  />
                ) : (
                  <th key={c.name}>{c.name}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {data.display.length > 0 ? (
              data.display.map((p) => <RowElement data={p} key={p.id} />)
            ) : (
              <tr>
                <td colSpan={7}>
                  <span style={{ fontSize: '1.5rem' }}>🏜</span>
                  &nbsp;{loading ? 'Buscando...' : `No hay ${entityNamePlural}`}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </figure>
    </>
  )
}
