import { FreeTheCoinsProps } from '../../types/pendingCoinTransfers'

export function FreeTheCoins(props: FreeTheCoinsProps) {
  const { handleClick, loading } = props
  return (
    <>
      <button onClick={handleClick} aria-busy={loading} disabled={loading}>
        Liberar fichas
      </button>
    </>
  )
}
