import {
  useContext,
  useRef,
  useState,
  useEffect,
  ChangeEventHandler
} from 'react'
import { AuthContext } from '../../../../components/authProvider'
import { ViewHeader } from '../../../../components/ViewHeader'
import { config } from '../../../../config'
import { APIResponse } from '../../../../types/api'
import { toast } from 'react-toastify'
import { IAuthContext } from '../../../../types/auth'
import { Center } from './styled'

export function QrView() {
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const imgRef = useRef<HTMLImageElement>(null)
  const [cacheBust, setCacheBust] = useState(1)
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState(config.SD.BOTS.MAIN)
  const [bots, setBots] = useState<string[]>([])

  useEffect(() => {
    fetchBots()
  }, [])

  // Descargar imagen
  useEffect(() => {
    fetchQR()
  }, [cacheBust, name])

  const fetchQR = () => {
    setLoading(true)
    apiInit()
      .then((api) =>
        api.get(`/bot/${name}?cacheBust=${cacheBust}`, { responseType: 'blob' })
      )
      .then((res) => {
        if (res.status === 200) {
          // Res.data is a binary image
          const reader = new FileReader()
          // Convert to data url
          reader.readAsDataURL(res.data)
          return new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result)
            reader.onerror = () => reject(reader.error)
          })
        }
      })
      .then((dataUrl) => {
        if (!imgRef.current) return
        imgRef.current.src = dataUrl as string
      })
      .finally(() => setLoading(false))
  }

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

  const fetchBots = () => {
    apiInit()
      .then((api) => api.get<APIResponse<string[]>>('/bot'))
      .then((res) => {
        if (res.status === 200) return res.data.data
        if (res.status !== 401)
          toast.error('Error en el servidor. Intentá de nuevo en unos minutos.')
        return []
      })
      .then(setBots)
      .catch(() => toast.error('Error de conexión'))
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => setCacheBust((prev) => prev + 1), 5000)

    return () => clearTimeout(timeoutId)
  }, [cacheBust])

  const handleBotSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setName(e.target.value)
  }

  return (
    <>
      <ViewHeader loading={loading} heading={'QR para vincular Whatsapp'} />
      <select name="bot" aria-label="Select bot" onChange={handleBotSelect}>
        {bots.map((bot) => (
          <option key={bot} value={bot}>
            {capitalize(bot)}
          </option>
        ))}
      </select>
      <Center>
        <img
          ref={imgRef}
          src=""
          alt="qr code"
          style={{ borderRadius: '1rem', overflow: 'hidden' }}
        />
      </Center>
    </>
  )
}
