import { useContext } from 'react'
import { ConfirmationDialogProps } from '../../types/payment'
import { Dialog } from '../../components/Dialog'
import { FinanceContext } from '../../components/financeProvider'

export function ConfirmationDialog({
  payment,
  dialogRef
}: ConfirmationDialogProps) {
  const { autoPayments } = useContext(FinanceContext)
  const heading = autoPayments ? 'Liberar Transferencia' : 'Confirmar Pago'
  const Message = autoPayments
    ? () => (
        <>
          <p>Estas por enviar un pago a: </p>
          <p>
            {payment.BankAccount.owner} ({payment.Player.username}) por un monto
            de {payment.amount}
          </p>
        </>
      )
    : () => (
        <>
          <p>Estas por confirmar el pago a: </p>
          <p>
            {payment.BankAccount.owner} ({payment.Player.username}) por un monto
            de {payment.amount}.
          </p>
          <p>
            <b>
              Esta acción no envía dinero, sólo indica que ya enviaste el pago a
              mano.
            </b>
          </p>
        </>
      )
  return (
    <Dialog dialogRef={dialogRef} heading={heading}>
      <Message />
    </Dialog>
  )
}
