import { toast } from "react-toastify";
import { CopyBtnProps } from "../types/copy";

export function CopyableText(props: CopyBtnProps) {
    const { content } = props;
    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(props.content)
        .then(() => toast("Copiado", { type: "success" }))
        .catch((): string | number =>
          toast("No pudimos copiar el número.", { type: "error" })
        );
    };
    return (
      <span
        onClick={copyToClipboard}
        aria-label="Copiar al portapapeles"
        style={{ cursor: "pointer" }}
        data-tooltip="Copiar"
      >
        {content}
      </span>
    );
  }