import styled from "styled-components";

export const Article = styled.article`
margin-top: calc(var(--main-inline-padding) + var(--main-inline-margin));
max-width: 80ch;
margin-inline: auto;
`;
export const Header = styled.header`
color: var(--pico-contrast);
`;
export const Footer = styled.footer`
display: flex;
justify-content: end;
`;
