import { MouseEventHandler } from 'react'
import { Span } from './styled'

export function CloseBtn({
  closeToast
}: {
  closeToast: MouseEventHandler<HTMLSpanElement>
}) {
  return <Span onClick={closeToast}>❌</Span>
}
