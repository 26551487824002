import { useContext, useRef, useState } from 'react'
import { AuthContext } from '../../components/authProvider'
import { toast } from 'react-toastify'
import { parseDate } from '../../components/table-utilities/parse-date'
import { PAYMENT_STATUS } from '../../config'
import { PaymentCTA } from './paymentCTA'
import { Dialog } from '../../components/Dialog'
import { CopyableText } from '../../components/CopyableText'
import { modalResult, showModal } from '../../components/Dialog/utils'
import { IAuthContext } from '../../types/auth'
import { PagoProps, Payment } from '../../types/payment'
import { FinanceContext } from '../../components/financeProvider'
import { ConfirmationDialog } from './confirmationDialog'

export function Pago({ data }: PagoProps) {
  const threeHours = 3 * 60 * 60 * 1000
  const { apiInit } = useContext(AuthContext) as IAuthContext
  const [payment, setPayment] = useState(data)
  const [busy, setBusy] = useState(false)
  const confirmDialog = useRef<HTMLDialogElement>(null)
  const { autoPayments } = useContext(FinanceContext)
  const STATUS_DESCRIPTION =
    'El jugador ha pedido un retiro de fondos. Flujo de pago no iniciado.'

  const background =
    payment.status !== PAYMENT_STATUS.COMPLETED &&
    Date.now() - +new Date(payment.created_at) > threeHours
      ? 'color-mix(in oklab, var(--pico-del-color), transparent 50%)'
      : 'unset'

  function pay(paymentId: string, action: 'paid' | 'release') {
    setBusy(true)
    apiInit()
      .then((api) => api.post(`/agent/payments/${paymentId}/${action}`))
      .then((res) => {
        if (res.status === 200) {
          return res.data.data
        }
        throw res
      })
      .then(paymentSuccessHandler)
      .catch(paymentErrorHandler)
      .finally(() => setBusy(false))
  }

  async function paymentSuccessHandler(payment: Payment) {
    setPayment((prev) => ({
      ...prev,
      status: payment.status,
      updated_at: payment.updated_at
    }))
    toast.success('👍 Ok')
  }

  async function paymentErrorHandler(err: any) {
    if (err.data.status === 403) toast.error(err.data.data)
    else if (err.data.status === 429)
      toast.error(
        'Demasiados intentos, volvé a intentar en ' +
          (Math.floor(err.headers['retry-after']) ?? 'unos') +
          ' segundos.'
      )
    else if (err.data.status === 422)
      toast.error(() => (
        <span>
          <b>Alquimia:</b> {err.data.data}
        </span>
      ))
    else
      toast.error('No pudimos confirmar el pago. Por favor volvé a intentar.')
  }

  async function confirmHandler(): Promise<boolean> {
    const dialog = confirmDialog.current

    if (!dialog) {
      toast.error(
        'No pudimos marcar el pago como completado. Por favor volvé a intentar.'
      )
      return false
    }
    await showModal(dialog)
    return await modalResult(dialog)
  }

  async function handleClick() {
    const action = autoPayments ? 'release' : 'paid'
    if (
      payment.status !== PAYMENT_STATUS.REQUESTED &&
      payment.status !== PAYMENT_STATUS.PENDING
    )
      return await pay(payment.id, action)

    const confirmed = await confirmHandler()
    if (confirmed) {
      pay(payment.id, action)
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <tr style={{ '--pico-background-color': background }}>
        <td>{parseDate(payment.created_at)}</td>
        <td>{payment.Player.username}</td>
        <td>{payment.amount}</td>
        <td>
          <CopyableText content={payment.BankAccount.owner} />
        </td>
        <td>
          <CopyableText content={payment.BankAccount.bankNumber} />
        </td>
        <td>
          <span
            data-tooltip={
              payment.status === PAYMENT_STATUS.REQUESTED
                ? STATUS_DESCRIPTION
                : undefined
            }
          >
            {payment.status}
          </span>
        </td>
        <td>
          {payment.status === PAYMENT_STATUS.COMPLETED
            ? parseDate(payment.updated_at)
            : ' - '}
        </td>
        <td>
          <PaymentCTA data={payment} cb={handleClick} busy={busy} />
        </td>
        <td style={{ padding: '0', margin: '0', height: '0', width: '0' }}>
          <ConfirmationDialog payment={payment} dialogRef={confirmDialog} />
        </td>
      </tr>
    </>
  )
}
