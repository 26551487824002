import { useCallback, useContext, useRef } from 'react'
import { ConfigItem } from '../../../components/ConfigItem'
import { FinanceContext } from '../../../components/financeProvider'
import { Dialog } from '../../../components/Dialog'
import { toast } from 'react-toastify'
import { modalResult, showModal } from '../../../components/Dialog/utils'

export function PaymentsConfig() {
  const { autoPayments, setAutoPayments } = useContext(FinanceContext)
  const dialogRef = useRef<HTMLDialogElement>(null)

  const confirmationHandler = useCallback(async () => {
    const dialog = dialogRef.current

    if (!dialog) {
      toast.error('Whoops! algo salió mal. Por favor volvé a intentar.')
      return false
    }

    await showModal(dialog)
    return await modalResult(dialog)
  }, [dialogRef])

  const toggleAutoPayments = useCallback(async () => {
    const confirmed = await confirmationHandler()

    if (!confirmed) return

    setAutoPayments(!autoPayments)
  }, [])

  const message = autoPayments
    ? 'Al desactivar esta opción los pagos deberan hacerse manualmente.'
    : 'Al activar esta opción los pagos se harán automáticamente al apretar "pagar" en la tabla de pagos.'

  return (
    <>
      <ConfigItem
        title={'Pagos automáticos'}
        subtitle={
          'Activa esta opción para pagos automáticos, desactiva para pagos manuales.'
        }
        toggleHandler={toggleAutoPayments}
        checked={autoPayments}
      />
      <Dialog heading={'⚠ Advertencia'} dialogRef={dialogRef}>
        {message}
      </Dialog>
    </>
  )
}
