import styled from 'styled-components'

export const NavHeader = styled.header<{ $visible: boolean }>`
  --_header-inline-padding: 0.5rem;
  --_header-border-width: 1px;
  padding-inline: var(--_header-inline-padding);
  border-bottom: var(--_header-border-width) solid #7777;
  position: sticky;
  top: 0;
  ${(props) => (!props.$visible ? 'backdrop-filter: blur(5px);' : '')}
  z-index: 2;
  background: color-mix(
    in oklab,
    var(--pico-background-color),
    transparent 50%
  );
`
export const Li = styled.li`
  position: relative;

  // Barra horizontal indicadora de página activa
  a::after {
    content: '';
    width: 0;
    margin-inline: auto;
    border: 0px solid var(--pico-secondary);
    border-bottom-width: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(var(--_header-border-width));
    border-radius: 5px;
    transition: width 0.15s ease-in;
  }
  a.active::after {
    content: '';
    width: 100%;
  }

  // Mover la barra a la izquierda del enlace en moviles
  @media (max-width: 768px) {
    a::after {
      height: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      transition: none;
      border-bottom-width: 0;
      border-left-width: 4px;
      transform: translateX(-1rem);
    }
    a.active::after {
      content: '';
      height: 100%;
      width: 0;
    }
  }
`

// Estilos de la barra de navegacion en moviles
export const ResponsiveNavBar = styled.ul`
  @media (max-width: 768px) {
    --font-size: 1.5rem;
    position: fixed;
    top: 1rem;
    right: 0;
    // bottom: 0;
    height: 100dvh;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      backdrop-filter: var(--pico-modal-overlay-backdrop-filter);
      background-color: var(--pico-modal-overlay-background-color);
    }
  }

  li {
    position: relative;
    z-index: 3;
  }
`

export const Dot = styled.span<{ $display: boolean }>`
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    width: 6px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: crimson;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    display: ${(props) => (props.$display ? 'inline-block' : 'none')};
  }

  &:hover {
    text-decoration: underline;
  }
`
