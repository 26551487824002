import { DialogProps } from "../../types/dialog";
import { Footer } from "./styled";

export function Dialog({heading, children, dialogRef }: DialogProps) {
    function closeDialog(returnValue: string) {
        if ('startViewTransition' in document)
          document.startViewTransition(() => dialogRef.current!.close(returnValue));
        else 
          dialogRef.current!.close(returnValue)
      }

    return <>
        <dialog ref={dialogRef}>
            <article>
            <header>
                <h1>{heading}</h1>
            </header>
            <main>
                {children}
            </main>
            <Footer className="grid">
                <button className="secondary" onClick={() => closeDialog("")}>
                Cerrar
                </button>
                <button value="submit" onClick={() => closeDialog("submit")}>
                Confirmar
                </button>
            </Footer>
            </article>
        </dialog>
    </>
}