import styled from 'styled-components'

export const Fieldset = styled.fieldset`
  margin-bottom: 0;
`

export const Input = styled.input`
  cursor: default;
`

export const Details = styled.details`
  margin: 0;
`
export const Summary = styled.summary`
  height: unset !important;
  padding: 0 !important;
  max-width: fit-content;

  &:after {
    display: none !important;
  }
`
export const A = styled.a`
  cursor: pointer;
`
