export const config = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    ENV: process.env.REACT_APP_NODE_ENV,
    UPTIME_MONITOR_URL: process.env.REACT_APP_UPTIME_MONITOR_URL,
    ACCESS_TOKEN_KEY: 'cm_agent_token',
    REFRESH_TOKEN_KEY: 'cm_agent_refresh',
    error: {
        '50*': 'Error del servidor',
        '40*': 'Error en el cliente'
    },
    SD: {
        INSUFICIENT_CREDITS: "FichasInsuficientes",
        DEPOSIT_STATUS: {
            PENDING: "pending",
            VERIFIED: "verified",
            CONFIRMED: "confirmed",
            COMPLETED: "completed",
            DELETED: "deleted",
          },
        BOTS: {
            MAIN: 'main',
            SUPPORT: "support",
            AD: "ad"
        },
    },

}

export enum PLAYER_STATUS {
    ACTIVE = "ACTIVO",
    BANNED = "BLOQUEADO"
}
export enum PAYMENT_STATUS {
    REQUESTED = "PEDIDO",
    PENDING = "PENDIENTE AUTORIZAR",
    PROCESSING = "EN PROCESO",
    COMPLETED = "LIQUIDADA",
    ERROR = "error",
}