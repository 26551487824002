import { useRef, useState } from 'react'
import { Pago } from './pago'
import { ViewHeader } from '../../components/ViewHeader'
import { PAYMENT_STATUS } from '../../config'
import { Table } from '../../components/Table'
import { TableData } from '../../types/table'
import { Payment } from '../../types/payment'

export default function Pagos() {
  const [loading, setLoading] = useState(true)

  const ExtraHeaderRow = () => (
    <tr>
      <th colSpan={3} style={{ border: 'none' }}></th>
      <th colSpan={2}>Cuenta bancaria</th>
      <th style={{ border: 'none' }}></th>
    </tr>
  )

  const filter = (
    payments: TableData<Payment>,
    searchingBy: string,
    filteringBy?: string
  ) => {
    const filtered = payments.all.filter((p) => {
      let result
      switch (filteringBy) {
        case PAYMENT_STATUS.PENDING:
          result = p.status !== PAYMENT_STATUS.COMPLETED
          break
        case PAYMENT_STATUS.COMPLETED:
          result = p.status === PAYMENT_STATUS.COMPLETED
          break
        default:
          result = true
      }
      return result
    })

    if (!searchingBy) {
      return filtered
    }

    return filtered.filter(
      (p) =>
        p.Player.username.toLowerCase().includes(searchingBy) ||
        p.Player.first_name?.toLowerCase()?.includes(searchingBy) ||
        p.Player.last_name?.toLowerCase()?.includes(searchingBy) ||
        p.BankAccount.bankNumber.toLowerCase().includes(searchingBy) ||
        p.BankAccount.owner.toLowerCase().includes(searchingBy)
    )
  }

  return (
    <>
      <ViewHeader
        heading="Historial de Pagos"
        subheading="Agente 👉 jugador"
        loading={loading}
      />
      <Table
        RowElement={Pago}
        ExtraTableHeadRow={<ExtraHeaderRow />}
        columns={[
          { selector: 'created_at', name: 'Fecha', type: 'datetime' },
          { selector: 'Player.username', name: 'Usuario', type: 'string' },
          { selector: 'amount', name: 'Monto', type: 'number' },
          { selector: 'BankAccount.owner', name: 'Titular', type: 'string' },
          { name: 'Cuenta CLABE' },
          { name: 'Estado' },
          { selector: 'updated_at', name: 'Pagado', type: 'string' }
        ]}
        entityNamePlural="pagos"
        filter={filter}
        loading={loading}
        setLoading={setLoading}
        searchPlaceholder="Buscar por usuario, nombre o datos bancarios"
        url="/transactions/payment"
        defaultFilter={PAYMENT_STATUS.PENDING}
        quickFilters={[
          {
            label: 'Pendientes',
            value: PAYMENT_STATUS.PENDING,
            ref: useRef()
          },
          {
            label: 'Pagados',
            value: PAYMENT_STATUS.COMPLETED,
            ref: useRef()
          },
          {
            label: 'Todos',
            value: 'all',
            ref: useRef()
          }
        ]}
      />
    </>
  )
}
