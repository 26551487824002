import { useContext, useState } from 'react'
import { Alert } from '../../components/Alert'
import { FinanceContext } from '../../components/financeProvider'
import { toast } from 'react-toastify'
import { PendingCTProps } from '../../types/pendingCoinTransfers'
import { FreeTheCoins } from './freeTheCoins'

export function PendingCoinTransfers({ amount }: PendingCTProps) {
  const { freePendingTransfers, fetchPendingCoinTransfers } =
    useContext(FinanceContext)
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    freePendingTransfers()
      .then(fetchPendingCoinTransfers)
      .catch(() => toast('Algo salió mal. Por favor intentá de nuevo.'))
      .finally(() => setLoading(false))
  }

  return (
    <Alert
      title="Hay transferencias de fichas pendientes"
      message={`Hay un total de ${amount} fichas esperando para transferirse a los jugadores. Si ya recargaste, tocá el botón para liberarlas.`}
      type="error"
      cta={<FreeTheCoins handleClick={handleClick} loading={loading} />}
    />
  )
}
