export async function showModal(dialog: HTMLDialogElement): Promise<void> {
  if ('startViewTransition' in document)
    await document.startViewTransition(() => dialog.showModal())
  else dialog.showModal()
}

export function modalResult(dialog: HTMLDialogElement): Promise<boolean> {
  return new Promise((resolve) => {
    dialog.onclose = () => {
      resolve(dialog.returnValue === 'submit')
      dialog.onclose = null
    }
  })
}
