import styled from 'styled-components'

export const Wrapper = styled.div`
  --highlight-width: auto;
  --highlight-x-pos: 0;
  margin-block-end: var(--pico-spacing);
`

export const Controls = styled.div`
  --pico-border-color: var(--pico-form-element-border-color);

  display: inline-flex;
  justify-content: space-between;
  background: var(--pico-form-element-background-color);
  border: var(--pico-border-width) solid var(--pico-border-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 1000px;
  max-width: 500px;
  padding: calc(var(--pico-form-element-spacing-vertical) / 2)
    calc(var(--pico-form-element-spacing-horizontal) / 2);
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    background: var(--pico-contrast);
    border-radius: 8px;
    border-radius: 100px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: calc(var(--pico-spacing) / 2);
    bottom: calc(var(--pico-spacing) / 2);
    left: 0;
    z-index: 0;
  }

  &.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`

export const Segment = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;

  @media (max-width: 576px) {
    width: 100%;
  }
`

export const Input = styled.input`
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
`

export const Label = styled.label`
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 6px;
  transition: color 0.5s ease;
  color: var(--pico-contrast);

  &:is(.segment.active label) {
    color: var(--pico-contrast-inverse);
  }
`
