import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AuthContext } from "../authProvider";
import { CheckIcon } from "../icons/check";
import { IAuthContext } from "../../types/auth";
import { BalanceWrapper } from "./styled";
import { BalanceResponse } from "../../types/balance";

export function Balance() {
    const { apiInit } = useContext(AuthContext) as IAuthContext;
    const [ loading, setLoading ] = useState(true);
    const [ balanceCMX, setBalanceCMX ] = useState<BalanceResponse>({ balance: undefined })
    const [ balanceALQ, setBalanceALQ ] = useState<BalanceResponse>({ balance: undefined })
    const [ showCheck, setShowCheck ] = useState(false);

    useEffect(() => {
        loadBalance('casino', setBalanceCMX);
        loadBalance('alquimia', setBalanceALQ);
    }, []);

    const update = () => {
        Promise.all([
          loadBalance("casino", setBalanceCMX),
          loadBalance("alquimia", setBalanceALQ),
        ]).then(() => {
          setShowCheck(true);
          setTimeout(() => setShowCheck(false), 2000);
        });
    }

    const loadBalance = (endpoint: string, setter: Dispatch<SetStateAction<BalanceResponse>>) => {
        setLoading(true);
        return apiInit()
        .then(api => api.get(`/agent/balance/${endpoint}`))
        .then((res) => {
            if (res.status === 200) return res.data.data;
            throw res;
        })
        .then(setter)
        .catch(() => {})
        .finally(() => setLoading(false))
    }

    return (
      <>
        <BalanceWrapper
          data-tooltip="Actualizar"
          data-placement="bottom"
          onClick={update}
        >
          <small>
            <ins>Balance</ins>{" "}
            <span aria-busy={loading}>
              <CheckIcon
                width={16}
                height={16}
                style={{ display: showCheck ? "inline" : "none" }}
              />
            </span>
          </small>
          <div>
            <span>
              <small>CMX {balanceCMX.balance}</small>
            </span>&nbsp;
            <span>
              <small>ALQ {balanceALQ.balance}</small>
            </span>
          </div>
        </BalanceWrapper>
      </>
    );
}