import { ReactElement } from "react";
import { Article, Footer, Header } from "./styled";

export function Alert(props: AlertProps) {
    return (
      <>
        <Article>
          <Header
            style={{
              background:
                props.type === "error"
                  ? "var(--pico-del-color)"
                  : "var(--pico-ins-color)",
            }}
          >
            <b>{props.title}</b>
          </Header>
          {props.message}
          { props.cta && 
            <Footer>
                { props.cta }
            </Footer>
          }
        </Article>
      </>
    );
}

interface AlertProps {
    title: string;
    message: string;
    type: "success" | "error";
    cta: ReactElement;
}