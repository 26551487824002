import { parseDate } from '../../components/table-utilities/parse-date'
import { Analytics } from '../../types/analytics'

export function Analytic({ data }: { data: Analytics }) {
  return (
    <>
      <tr>
        <td>{data.source}</td>
        <td>{data.event}</td>
        <td>{JSON.stringify(data.data)}</td>
        <td>{parseDate(data.created_at)}</td>
      </tr>
    </>
  )
}
