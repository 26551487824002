import { ChangeEventHandler, useEffect, useState } from "react";
import { useSummary } from "./useSummary";

export function AnalyticsSummary() {
  const { summary } = useSummary();
  const [source, setSource] = useState("");
  const [sources, setSources] = useState<string[]>([]);
  const [totalEvents, setTotalEvents] = useState(0);

  const sourceChangeHandler: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setSource(e.target.value);
  };

  useEffect(() => {
    const set = new Set<string>();
    summary?.forEach((s) => {
      set.add(s.source);
    });

    setSources(Array.from(set));
  }, [summary]);

  useEffect(() => {
    let totalEvents = 0;
    summary?.forEach((s) => {
        s.source === source && (totalEvents += s._count.event);
    })
    setTotalEvents(totalEvents);
  }, [source, summary])

  return (
    <>
      <h2>Resumen</h2>

      <select name="select" aria-label="select" onChange={sourceChangeHandler}>
        <option selected disabled value="">
          Fuente
        </option>
        {sources.map((source) => (
          <>
            <option key={source}>{source}</option>
          </>
        ))}
      </select>

      {(summary && source &&
        summary.map((s) =>
          s.source === source ? (
            <div key={s.event}>
              <label htmlFor={s.event}>
                {s.event}: {s._count.event}
              </label>
              <progress id={s.event} value={s._count.event} max={totalEvents} />
            </div>
          ) : null
        )) || <span>👆 Selecciona una fuente para ver el resumen</span>}
    </>
  );
}
