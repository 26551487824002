import styled from 'styled-components'

export const TableAction = styled.button`
  a,
  a:hover,
  a:active {
    --pico-color: none;
    --pico-text-decoration: none;
  }
`

export const Fieldset = styled.fieldset`
  margin-bottom: 0;
`

export const Input = styled.input`
  cursor: default;
`
