import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { ViewHeader } from '../../components/ViewHeader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../components/authProvider'
import { Deposit, DepositResponse } from '../../types/deposit'
import { parseDate } from '../../components/table-utilities/parse-date'
import { toast } from 'react-toastify'
import { config } from '../../config'
import { parseDepositStatus } from './utils'
import { Fieldset, Input } from './styled'
import { FormFooter } from '../../components/FormFooter'

export function EditDeposit() {
  const { depositId } = useParams()
  const { apiInit } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [deposit, setDeposit] = useState<Deposit>()
  const [sendBtnText, setSendBtnText] = useState('Verificar')
  const [status, setStatus] = useState({
    translatedStatus: '',
    description: ''
  })
  const [sendBtnTooltipText, setSendBtnTooltipText] = useState('')

  const trackingNumberRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()

  /** Load deposit */
  useEffect(() => {
    apiInit()
      .then((api) => api.get(`/transactions/deposit/${depositId}`))
      .then((res) => {
        if (res.status === 200) {
          const deposit: Deposit = res.data.data[0]
          if (
            !deposit ||
            deposit.status === config.SD.DEPOSIT_STATUS.COMPLETED ||
            deposit.status === config.SD.DEPOSIT_STATUS.DELETED
          ) {
            toast('Ese depósito no se puede modificar', { type: 'error' })
            return navigate('/depositos')
          }
          setDeposit(res.data.data[0])
          trackingNumberRef.current?.focus()
        } else throw res
      })
  }, [])

  useEffect(() => {
    switch (deposit?.status) {
      case config.SD.DEPOSIT_STATUS.PENDING:
        setSendBtnText('Verificar')
        setSendBtnTooltipText('Verificar que el depósito haya ingresado')
        break
      case config.SD.DEPOSIT_STATUS.VERIFIED:
        setSendBtnText('Enviar fichas')
        setSendBtnTooltipText('Cargar fichas en la cuenta del jugador')
        break
      case config.SD.DEPOSIT_STATUS.CONFIRMED:
        setSendBtnText('Completar')
        setSendBtnTooltipText('')
        break
    }
    if (deposit) setStatus(parseDepositStatus(deposit.status))
  }, [deposit])

  const saveStatus = () => {
    const form = formRef.current
    if (!form) return toast.error('Whoops, eso no funcionó.')
    setLoading(true)
    apiInit()
      .then((api) =>
        api.post<DepositResult>(`/transactions/deposit/${depositId}/update`, {
          status: form.status.value
        })
      )
      .then((res) => {
        console.log('RES STATUS', res.status === 200)
        if (res.status === 200) {
          console.log('BAZ')
          return res.data.data
        } else throw res
      })
      .then((data) => {
        setDeposit(data)
        const { translatedStatus } = parseDepositStatus(data.status)
        toast(`Deposito ${translatedStatus}`, { type: 'success' })
        trackingNumberRef.current?.focus()
      })
      .catch((err) => {
        if (err.status === 400 && err.data.code === 'already_exists')
          toast('Un depósito con ese número de seguimiento ya existe', {
            type: 'error'
          })
        else if (err.status > 400 && err.status <= 403)
          toast.error(err.data.data)
        else
          toast('Algo salió mal, por favor volvé a intentar. FOO', {
            type: 'error'
          })
      })
      .finally(() => setLoading(false))
  }

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    setDeposit((prev) => {
      if (!prev) return
      return {
        ...prev,
        [e.target.name]: e.target.value ?? ''
      }
    })
  }

  const continueDepositFlow = async () => {
    try {
      setLoading(true)
      const api = await apiInit()
      const res = await api.post<DepositResponse>(
        `/transactions/deposit/${depositId}`,
        {
          tracking_number: deposit?.tracking_number,
          amount: deposit?.amount,
          date: deposit?.date,
          sending_bank: deposit?.sending_bank
        }
      )

      if (res.status === 200) {
        setDeposit(res.data.data.deposit)
        if (res.data.data.error) toast.error(res.data.data.error)
        else toast.success('👍 OK')
      } else if (res.status >= 500)
        toast.error('Error de servidor, intentá de nuevo en unos minutos.')
      else toast.error('No pudimos hacer eso.')
    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ViewHeader loading={loading} heading={'Editar depósito'}>
        <blockquote>
          Depósitos <b>pendientes</b> de verificación: modificá el número de
          seguimiento (de ser necesario) y apretá "verificar" para validarlo. O
          validalo manualmente y usa el desplegable de <b>estado</b> para
          marcarlo como "verificado".
          <br />
          <br />
          Depósitos <b>verificados</b>: usa el botón "enviar fichas" para
          transferirle las fichas al jugador.
        </blockquote>
      </ViewHeader>
      <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
        <Fieldset className="grid">
          <label>
            ID
            <Input type="text" readOnly value={deposit?.id ?? ''} />
          </label>
          <label>
            Usuario
            <Input
              type="text"
              readOnly
              value={deposit?.Player.username ?? ''}
            />
          </label>
        </Fieldset>

        <Fieldset className="grid">
          <label>
            Nº de seguimiento
            <input
              ref={trackingNumberRef}
              type="text"
              value={deposit?.tracking_number ?? ''}
              name="tracking_number"
              onChange={handleChange}
              readOnly={deposit?.status !== config.SD.DEPOSIT_STATUS.PENDING}
            />
          </label>
          <label>
            Fecha
            <Input
              type="text"
              readOnly
              value={parseDate(deposit?.date) ?? ''}
            />
            <small>Hora local</small>
          </label>
        </Fieldset>

        <Fieldset className="grid">
          <label>
            Banco
            <Input type="text" readOnly value={deposit?.sending_bank ?? ''} />
          </label>
          <label>
            Monto
            <Input type="text" readOnly value={deposit?.amount ?? ''} />
          </label>
        </Fieldset>
        <label>
          Estado: &nbsp;
          <div role="group" style={{ marginBottom: 0 }}>
            <select
              name="status"
              value={deposit?.status}
              onChange={handleChange}
              disabled={
                deposit?.status === config.SD.DEPOSIT_STATUS.COMPLETED ||
                deposit?.status === config.SD.DEPOSIT_STATUS.DELETED
              }
            >
              {Object.values(config.SD.DEPOSIT_STATUS).map((s) => {
                const { translatedStatus } = parseDepositStatus(s)
                return (
                  <option value={s} key={s}>
                    {translatedStatus}
                  </option>
                )
              })}
            </select>
            <button
              className="outline"
              style={{ textWrap: 'nowrap' }}
              onClick={saveStatus}
              disabled={loading}
            >
              Guardar
            </button>
          </div>
          <small
            style={{
              color: 'var(--pico-muted-color)'
            }}
          >
            {status.description}
          </small>
        </label>

        <FormFooter style={{ marginTop: 'var(--pico-spacing)' }}>
          <Link to=".." style={{ justifySelf: 'flex-start' }}>
            <button className="secondary">
              {deposit?.status === config.SD.DEPOSIT_STATUS.PENDING
                ? 'Cancelar'
                : 'Volver'}
            </button>
          </Link>
          <button
            data-tooltip={sendBtnTooltipText}
            disabled={
              deposit?.status === config.SD.DEPOSIT_STATUS.COMPLETED ||
              deposit?.status === config.SD.DEPOSIT_STATUS.DELETED ||
              loading
            }
            onClick={continueDepositFlow}
            style={{ textWrap: 'nowrap', width: '100%' }}
          >
            {sendBtnText}
          </button>
        </FormFooter>
      </form>
    </>
  )
}

type DepositResult = {
  status: number
  code: string
  data: Deposit
}
