import styled from "styled-components";


export const Input = styled.input`
  width: 5rem !important;
  display: inline-block;
  font-size: .75rem !important;
  height: 1.75rem !important;
  margin: 0 !important;
`;
export const Wrapper = styled.div`
  --bar-width: 3px;
  display: flex;
  gap: var(--pico-spacing);
  align-items: center;
  padding-left: calc(var(--pico-spacing) - var(--bar-width));
  border-left: var(--bar-width) solid var(--pico-form-element-border-color);
  margin-block: var(--pico-spacing);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Div = styled.div`
  @media (min-width: 768px) {
    border-right: var(--bar-width) solid var(--pico-form-element-border-color);
    padding-right: var(--pico-spacing);
  }
`;